import React, { useState } from "react"
import { Modal, Button } from "react-bootstrap"
import HubspotForm from "react-hubspot-form"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Helmet } from "react-helmet"
import { Link } from "gatsby"
import useLiveControl from "../controlled/useLiveControl"
import LiveControlSpinner from "../components/liveControlSpinner"
import Layout from "../components/layout"
import Seo from "../components/seo"
import Card from "react-bootstrap/Card"

const ContractorApplication = () => {
  const { runningChecks } = useLiveControl()

  const [show, setShow] = useState(false)
  // const [message, setMessage] = useState(false)
  // const { register, handleSubmit, errors } = useForm();
  const message = false
  const handleClose = () => setShow(false)
  // const onSubmit = (data, e) => {
  //   var xhr = new XMLHttpRequest()
  //   var url =
  //     "https://api.hsforms.com/submissions/v3/integration/submit/381510/97ff0915-f670-4588-ad0b-1ae644a652b1"
  //   var sendData = {
  //     fields: [
  //       {
  //         name: "company",
  //         value: data.companyName,
  //       },
  //       {
  //         name: "firstname",
  //         value: data.firstName,
  //       },
  //       {
  //         name: "lastname",
  //         value: data.lastName,
  //       },
  //       {
  //         name: "email",
  //         value: data.email,
  //       },
  //       {
  //         name: "phone",
  //         value: data.phone,
  //       },
  //     ],
  //   }
  //   var finel_data = JSON.stringify(sendData)
  //   xhr.open("POST", url)
  //   xhr.setRequestHeader("Content-Type", "application/json")
  //   xhr.setRequestHeader("Access-Control-Allow-Origin", "*")
  //   xhr.setRequestHeader("Access-Control-Allow-Methods", "POST")
  //   xhr.onreadystatechange = function() {
  //     if (xhr.readyState === 4 && xhr.status === 200) {
  //       // alert(xhr.responseText.split('"', 4)[3]);
  //       setMessage(xhr.responseText.split('"', 4)[3])
  //       setShow(true)
  //       e.target.reset()
  //     } else if (xhr.readyState === 4 && xhr.status === 400) {
  //       setMessage(JSON.parse(xhr.responseText).errors[0].message)
  //       setShow(true)
  //     } else if (xhr.readyState === 4 && xhr.status === 403) {
  //       setMessage(xhr.responseText)
  //       setShow(true)
  //     } else if (xhr.readyState === 4 && xhr.status === 404) {
  //       setMessage(xhr.responseText)
  //       setShow(true)
  //     }
  //   }
  //   xhr.send(finel_data)
  // }

  if (runningChecks) return <LiveControlSpinner />

  return (
    <Layout>
      <Seo
        title={"Get Started"}
        description={"America's home improvement lender of choice"}
      />
      <Helmet>
        <body className="contractor-application-page html-hs-form" />
      </Helmet>
      <section className="container pb-0">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <h1 className="text-center mb-5 text-blue">
                Apply to Join the EnerBank Loan Program Today!
              </h1>
              <p>
                If you&#39;ve done the research and are ready to start offering
                payment options from Regions | EnerBank, then you&#39;ve come to
                the right place! Just fill out the information below and a
                member of our team will be in touch with any other information
                we may need to get you started.{" "}
              </p>
            </div>
          </div>
        </div>
      </section>
      <section className="app-form pb-5">
        <div className="container">
          <div className="row">
            <div className="col-md-12 pb-5">
              <HubspotForm
                portalId="381510"
                formId="4c6365fc-02c3-48b8-88a7-65e3d09ebd05"
                onSubmit={() => this.handleFormSubmit}
                onReady={form => console.log("Form ready!")}
                loading={<div>Loading...</div>}
              />
            </div>

            <div className="col-md-12">
              <hr />
              <h3 className="text-center py-3 pt-4">
                Looking for More Information?
              </h3>
              <p className="text-center">
                Not quite ready to apply, but want to know more about our loan
                programs?{" "}
              </p>
              <p className="text-center pt-3">
                <Link to={"/sign-up/"} className="m-auto px-5 btn btn-primary">
                  Learn More
                </Link>
              </p>
            </div>
          </div>
        </div>
      </section>
      <section className="bg-blue">
        <div className="container">
          <div className="row">
            <div className="col-md-12 pb-5">
              <h2 className="text-center text-white pb-3">
                Getting Started with EnerBank is Easy and Fast
              </h2>
            </div>
            <div className="col-md-6 text-white">
              <h3 className="text-white pb-3">
                You could be offering EnerBank financing in as little as 3
                weeks!
              </h3>
              <p>
                Experience a fast and helpful onboarding process with{" "}
                <strong>
                  {" "}
                  <br />
                  no sign-up fees
                </strong>
                . Then EnerBank can help you focus on growing your core business
                by giving your customers financing options that best meet their
                needs, like Same-As-Cash Loans or low monthly payments.
              </p>
            </div>
            <div className="col-md-6">
              <div className="list-box">
                <h3>Do You Qualify for Our Program?</h3>
                <p>
                  To offer payment options, you must meet the following
                  qualifications:
                </p>
                <ul className="fa-ul">
                  <li>
                    <i className="fa-li">
                      <FontAwesomeIcon
                        className="eb-green"
                        icon="square-check"
                      />
                    </i>
                    Your business has a focus on residential home remodeling
                  </li>
                  <li>
                    <i className="fa-li">
                      <FontAwesomeIcon
                        className="eb-green"
                        icon="square-check"
                      />
                    </i>
                    You have been in operation for at least three years
                  </li>
                  <li>
                    <i className="fa-li">
                      <FontAwesomeIcon
                        className="eb-green"
                        icon="square-check"
                      />
                    </i>
                    An average project size of at least $5,000
                  </li>
                  <li>
                    <i className="fa-li">
                      <FontAwesomeIcon
                        className="eb-green"
                        icon="square-check"
                      />
                    </i>
                    Annual residential remodeling sales of at least $500,000
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section>
        <div className="container">
          <div className="row">
            <div className="col-md-12 pb-5">
              <h2 className="text-center pb-3">
                Technology That Makes Offering Payment Options Easy
              </h2>
            </div>
            <div className="col-md-4 pb-5">
              <Card>
                <Card.Img
                  variant="top"
                  src="http://gbdevpress.enerbank.com/wp-content/uploads/2023/03/customer-prequal.png"
                  alt="customer pre-qualification"
                />
                <Card.Body>
                  <Card.Title className="text-center">
                    Customer
                    <br /> Pre-Qualification
                  </Card.Title>
                </Card.Body>
              </Card>
            </div>
            <div className="col-md-4 pb-5">
              <Card>
                <Card.Img
                  variant="top"
                  src="http://gbdevpress.enerbank.com/wp-content/uploads/2023/03/choice-app-methods.png"
                  alt="choice of application methods"
                />
                <Card.Body>
                  <Card.Title className="text-center">
                    A Chioce of
                    <br /> Application Methods
                  </Card.Title>
                </Card.Body>
              </Card>
            </div>
            <div className="col-md-4 pb-5">
              <Card>
                <Card.Img
                  variant="top"
                  src="http://gbdevpress.enerbank.com/wp-content/uploads/2023/03/express-credit-decisions.png"
                  alt="express credit decisions"
                />
                <Card.Body>
                  <Card.Title className="text-center">
                    Express Credit
                    <br /> Decisions
                  </Card.Title>
                </Card.Body>
              </Card>
            </div>
          </div>
          <div className="row justify-content-center">
            <div className="col-md-4">
              <Card>
                <Card.Img
                  variant="top"
                  src="http://gbdevpress.enerbank.com/wp-content/uploads/2023/03/fast-payments.png"
                  alt="faster payments"
                />
                <Card.Body>
                  <Card.Title className="text-center">
                    Fast
                    <br /> Payments
                  </Card.Title>
                </Card.Body>
              </Card>
            </div>
            <div className="col-md-4">
              <Card>
                <Card.Img
                  variant="top"
                  src="http://gbdevpress.enerbank.com/wp-content/uploads/2023/03/online-reporting.png"
                  alt="online reporting & tracking tool"
                />
                <Card.Body>
                  <Card.Title className="text-center">
                    Online Reporting
                    <br /> & Tracking Tool
                  </Card.Title>
                </Card.Body>
              </Card>
            </div>
          </div>
        </div>
      </section>
      <section className="bg-light-gray pt-0">
        <div className="bg-blue py-4">
          <div className="container">
            <h2 className="text-center text-white">
              Ready to Work with a Lender That Puts You First?
            </h2>
          </div>
        </div>
        <div className="container ready-to-work">
          <div className="row">
            <article>
              <ul>
                <li className="mb-4 d-flex align-items-start">
                  <div className="wcf-img-li-box mr-3">
                    <img
                      src="http://gbdevpress.enerbank.com/wp-content/uploads/2023/03/dedicated-rm.png"
                      alt="dedicated relationship manager"
                    />
                  </div>
                  <p className="ml-2">
                    <span className="font-weight-bold">
                      Dedicated Relationshiop Manager{" "}
                    </span>{" "}
                    — Acting as your single point of contact at the bank,
                    they&#39;re familiar with your account and can help with
                    many aspects of your business.
                  </p>
                </li>
                <li className="mb-4 d-flex align-items-start">
                  <div className="wcf-img-li-box mr-3">
                    <img
                      src="http://gbdevpress.enerbank.com/wp-content/uploads/2023/03/marketing-tools.png"
                      alt="marketing tools"
                    />
                  </div>
                  <p className="ml-2">
                    <span className="font-weight-bold">Marketing Tools </span> —
                    As a part of your program you gain access to high-quality
                    marketing materials to help your business make a great
                    impression.
                  </p>
                </li>
                <li className="mb-4 d-flex align-items-start">
                  <div className="wcf-img-li-box mr-3">
                    <img
                      src="http://gbdevpress.enerbank.com/wp-content/uploads/2023/03/us-based-customer-support.png"
                      alt="U.S. based customer support"
                    />
                  </div>
                  <p className="ml-2">
                    <span className="font-weight-bold">
                      U.S.-Based Customer Support{" "}
                    </span>{" "}
                    — With hours beyond the usual 9 to 5, you can get the help
                    you need when you need it.
                  </p>
                </li>
                <li className="mb-0 d-flex align-items-start">
                  <div className="wcf-img-li-box mr-3">
                    <img
                      src="http://gbdevpress.enerbank.com/wp-content/uploads/2023/03/on-demand-trainning.png"
                      alt="on demand-training"
                    />
                  </div>
                  <p className="ml-2">
                    <span className="font-weight-bold">
                      On-Demand Training{" "}
                    </span>{" "}
                    — Ensure that every member of your team is up to date on
                    best practices to help ensure you&#39;re getting the most
                    out of your payment options.
                  </p>
                </li>
              </ul>
            </article>
          </div>
        </div>
      </section>
      <Modal show={show} onHide={handleClose}>
        <Modal.Body
          className="p-4 mt-5 text-center"
          dangerouslySetInnerHTML={{ __html: message }}
        />
        <Modal.Footer className="border-0">
          <Button variant="primary mb-3 mx-auto" onClick={handleClose}>
            OK
          </Button>
        </Modal.Footer>
      </Modal>
    </Layout>
  )
}

export default ContractorApplication

// export const query = graphql`
//     query($databaseId: ID!) {
//         wpgraphql {
//             page(id: $databaseId, idType: DATABASE_ID) {
//                 title
//                 date
//                 content(format: RENDERED)
//                 featuredImage {
//                     altText
//                     title(format: RENDERED)
//                     mediaItemUrl
//                     slug
//                 }
//             }
//         }
//     }
// `
